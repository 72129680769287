@import url("https://fonts.googleapis.com/css?family=Nunito&display=swap");

* {
  padding: 0;
  margin: 0;
  /* box-sizing: border-box; */
  font-family: "Nunito", Arial, Helvetica, sans-serif;
}

body {
  /* background-color: #001220; */
  background-color: #000d14;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: .25rem
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 5px;
}

.App {
  min-height: 100dvh;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App:has(.projects) {
  display: block;
}

.content {
  z-index: 1;
  padding-bottom: 100px;
}

.svg-top {
  position: fixed;
  max-width: 70px;
  top: 2%;
  right: 2%;
}

.svg-bottom {
  position: fixed;
  max-width: 70px;
  bottom: 2%;
  left: 2%;
}

.bottom-nav-container {
  z-index: 10;
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation {
  position: relative;
  width: 240px;
  height: 80px;
  border-radius: 40px;
  background-color: #fff;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
}

.navigation ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
}

.navigation ul li {
  position: relative;
  list-style: none;
  width: 80px;
  height: 80px;
  z-index: 1;
}

.navigation ul li a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  color: #333;
  font-weight: 500;
}

.navigation ul li a .icon {
  position: relative;
  display: block;
  line-height: 80px;
  text-align: center;
  transition: 0.5s;
}

.navigation ul li.active a .icon {
  color: #fff;
}

.navigation ul li a .icon i {
  font-size: 20px;
}

.navigation ul li a .title {
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translate(-50%, 15%);
  width: auto;
  background: #fff;
  padding: 5px 10px;
  border-radius: 7px;
  transition: 0.5s;
  opacity: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  visibility: hidden;
}

.navigation ul li:hover a .title {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, 50%);
}

.navigation ul li a .title::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #fff;
  bottom: -8px;
  left: 48%;
  transform: rotate(45deg) translateX(-50%);
  border-radius: 2px;
}

.navigation ul .indicator {
  position: absolute;
  left: 0;
  width: 80px;
  height: 80px;
  transition: all 0.5s;
}

.navigation ul .indicator::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #333;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: 0.5s;
}

.navigation ul li:nth-child(1).active~.indicator {
  transform: translateX(calc(80px * 0));
}

.navigation ul li:nth-child(2).active~.indicator {
  transform: translateX(calc(80px * 1));
}

.navigation ul li:nth-child(3).active~.indicator {
  transform: translateX(calc(80px * 2));
}

.navigation ul li:nth-child(1).active~.indicator::before {
  background-color: crimson;
}

.navigation ul li:nth-child(2).active~.indicator::before {
  background-color: #3c40c6;
}

.navigation ul li:nth-child(3).active~.indicator::before {
  background-color: #05c46b;
}



/* HOME STYLE */
.homepage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.homepage img {
  max-width: 350px;
}

.name-title {
  padding: 15px;
  text-align: center;
}

.name-title h1 {
  font-size: 35px;
  letter-spacing: 3px;
  color: wheat;
}

.name-title h2 {
  font-size: 20px;
  letter-spacing: 3px;
  color: crimson;
}

.contacts {
  gap: 20px;
}

.contact img {
  max-width: 30px;
  cursor: pointer;
}


/* ABOUT STYLE */
.about {
  max-width: 100%;
  margin: 0 auto;
}

.about .section-title {
  text-shadow: 1px 2px black;
  box-shadow: inset 0px -15px 0px 0px #3c40c6;
}

.skills span {
  color: #bac0c4;
  border: 1px solid;
  border-radius: 50rem;
}



.fadeLeftMini {
  animation: fadeLeftMini 1s;
  animation-fill-mode: both;
}

.fadeRightMini {
  animation: fadeRightMini 1s;
  animation-fill-mode: both;
}

@keyframes fadeLeftMini {
  0% {
    opacity: 0;
    transform: translateY(-30%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeRightMini {
  0% {
    opacity: 0;
    transform: translateY(30%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}



.privacy-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  /* background: rgb(237, 20, 61);
  background: linear-gradient(90deg, rgba(237, 20, 61, 1) 10%, rgba(60, 64, 198, 1) 50%, rgba(5, 196, 107, 1) 90%); */
}

.progress {
  position: fixed;
  left: 5px;
  right: 5px;
  height: 5px;
  background: #05c46b;
  z-index: 1;
  bottom: 110px;
}


/* //PROJECTS STYLE */

.projects .section-title {
  text-shadow: 1px 2px black;
  box-shadow: inset 0px -15px 0px 0px #05c46b;
}

.card-hashtag {
  position: absolute;
  right: -20px;
  top: -20px;
  background: #05c46b;
  width: 100px;
  border-radius: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.projects .section-description {
  max-width: 800px;
  margin: 0 auto;
}

.projects_box {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  grid-gap: 1rem;
  justify-items: center;
  padding: 1rem;
  margin: 0 auto;
  max-width: 1000px;
}

.projects_item {
  max-width: 350px;
  height: 100%;
  overflow: hidden;
  border-radius: 15px;
  width: 100%;
}

.projects_item[data-isopen="true"] {
  position: fixed;
  min-width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #141414;
  z-index: 11;
  margin: 0;
  border-radius: 0px;
  overflow: auto;
  /* padding-bottom: 100px; */
  /* display: flex; */
  /* align-items: center;
  justify-content: center; */
}

.projects-item_link {
  padding: 30px 20px;
  background-color: #141414;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.projects-item_link[data-isopen="true"] {
  max-width: 1000px;
  margin: 0 auto;
  height: auto;
}

.on-top {
  z-index: 12412;
}

.projects-item_title {
  margin: 0 0 10px;
  overflow: hidden;
  font-weight: bold;
  font-size: 25px;
  color: #FFF;
  z-index: 2;
  position: relative;
}


.projects-item_date {
  font-size: 18px;
  color: #FFF;

  z-index: 2;
  position: relative;
}


.project-card-button {
  cursor: pointer;
  min-width: 70px;
  padding: 0 10px;
  height: 32px;
  background: #3ecd5e;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  align-self: center;
  margin-top: 25px;
  z-index: 1;
  user-select: none;
  font-weight: bold;
  color: #141414
}

.project-button-container {
  display: flex;
  gap: 10px;
}

.project-card-button img {
  max-width: 28px;
}

.project-card-button.icon {
  background-color: #fff;
}

.project-button-container[data-isopen="true"] {
  justify-content: center;
}


/* GALERY STYLE */

.project-gallery-container {
  width: 100%;
  max-height: 500px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.next,
.prev {
  top: calc(50% - 20px);
  position: absolute;
  background: white;
  border-radius: 15px;
  color: #000d14;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
  font-weight: bold;
  z-index: 2;
}

.next .far {
  font-weight: bold;
}

.prev .far {
  font-weight: bold;
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
  transform: scale(-1);
}

.project-gallery-container img {
  position: absolute;
  max-width: 100%;
  max-height: 500px;
  object-fit: contain;
}


/* Media Queries */

@media screen and (min-width: 768px) {
  .name-title h1 {
    font-size: 50px;
  }

  .name-title h2 {
    font-size: 25px;
  }

  .svg-top {
    max-width: 120px;
  }

  .svg-bottom {
    max-width: 120px;
  }

  .about {
    max-width: 80%;
  }
}

@media screen and (min-width: 1600px) {
  .name-title h1 {
    font-size: 60px;
  }

  .name-title h2 {
    font-size: 35px;
  }

  .homepage img {
    max-width: 450px;
  }

  .contacts {
    gap: 20px;
  }

  .projects_box {
    max-width: 1200px;
  }


  .svg-bottom,
  .svg-top {
    max-width: 180px;
  }
}




@media only screen and (max-width: 979px) {
  .projects-item_title {
    font-size: 24px;
  }
}


@media only screen and (max-width: 639px) {
  .project-gallery-container {
    max-height: 350px !important;
  }

  .projects-item_title {
    font-size: 24px;
  }

  .projects-item_link {
    padding: 15px;
  }

  .projects-item_date {
    font-size: 16px;
  }
}